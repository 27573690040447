import React from 'react';
import { MapContainer, TileLayer, Circle, Tooltip, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

// Props type definition
interface OSMMapProps {
    latitude: number | undefined;
    longitude: number | undefined;
    zoom?: number;
    tooltip?: string;
    radius?: number;
}

// Add the gestureHandling to Leaflet
const GestureHandlingSetter = () => {
    /* eslint-disable */
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler("gestureHandling", GestureHandling);
    /* eslint-enable */
    return null;
};

const OSMMap: React.FC<OSMMapProps> = ({ latitude, longitude, zoom = 15, tooltip, radius = 300 }) => {

    if (!latitude || !longitude) {
        return null;
    }

    const position: [number, number] = [latitude, longitude];

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {/* Ensuring MapContainer respects the parent div's size */}
            <MapContainer
                center={position}
                zoom={zoom}
                scrollWheelZoom={false}
                style={{ width: '100%', height: '100%', borderRadius: '16px', minHeight: '400px' }}
            >
                <GestureHandlingSetter />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Circle center={position}
                    radius={radius}
                    color="#007AFF"
                    fillColor="#007AFF"
                    fillOpacity={0.2}
                >
                    {
                        tooltip ? <Tooltip sticky>{tooltip}</Tooltip> : null
                    }
                </Circle>
            </MapContainer>
        </div>
    );
};

export default OSMMap;
