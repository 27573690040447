import React from 'react';
import {
    Dialog,
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { googleAuthentication } from '../../services/authServices';

// Assets
import googleLogo from '../../assets/google-logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import hoveniLogo from "../../assets/hoveni-logo.svg";

interface LoginDialogProps {
    open: boolean;
    onClose: () => void;
}

const LoginDialog: React.FC<LoginDialogProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogin = async () => {
        // Save the current url to local storage
        localStorage.setItem('lastVisitedUrl', window.location.href);

        await googleAuthentication();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black backdrop
                    boxShadow: 'none',
                    maxWidth: isMobile ? '100%' : '600px',
                    minWidth: '360px',
                },
            }}
        >
            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{
                        width: isMobile ? '90%' : '400px',
                        padding: 4,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4
                    }}>
                    {/* Close button */}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8, }} // Close button color
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Logo */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'auto',
                        }}
                    >
                        <img
                            src={hoveniLogo}
                            alt="Hoveni Logo"
                            style={{
                                maxHeight: isMobile ? '48px' : '60px',
                                width: 'auto',
                            }}
                        />
                    </Box>

                    <Typography variant="h3" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Login to Continue
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                        Please log in with your Google account to continue the booking process.
                    </Typography>

                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <Button
                            variant="outlined"
                            onClick={handleLogin}
                            startIcon={
                                <img
                                    src={googleLogo}
                                    alt="Google Logo"
                                    style={{ width: 24, height: 24 }} // Set size for the icon
                                />
                            }
                            sx={{
                                color: theme.palette.text.primary,
                                borderColor: theme.palette.text.disabled,

                                width: '100%',
                                '&:hover': {
                                    backgroundColor: '#e8f0fe', // Light blue background on hover
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                        >
                            Login with Google
                        </Button>

                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
};

export default LoginDialog;
