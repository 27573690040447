import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Activity } from "../types";

interface AddedActivity {
  activity: Activity;
  homestayId: string;
}

interface ActivitiesState {
  activities: AddedActivity[];
}

const initialState: ActivitiesState = {
  activities: [],
};

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    addActivity: (state, action: PayloadAction<AddedActivity>) => {
      state.activities.push(action.payload);
    },
    removeActivity: (
      state,
      action: PayloadAction<{ activityId: string; homestayId: string }>
    ) => {
      state.activities = state.activities.filter(
        (item) =>
          item.activity.id !== action.payload.activityId ||
          item.homestayId !== action.payload.homestayId
      );
    },
    resetState: (state) => {
      state.activities = [];
    },
  },
});

export const { addActivity, removeActivity, resetState } =
  activitiesSlice.actions;
export default activitiesSlice.reducer;
