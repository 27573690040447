import { Button, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';

interface ButtonProps {
    variant?: 'normal' | 'outline';
    borderRadius?: string | number; // Optional custom border radius
    label: string;
    onClick?: () => void;
    sx?: React.CSSProperties;
    disabled?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
    variant = 'normal',
    borderRadius = '8px',
    label,
    onClick,
    disabled,
    sx,
}) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            sx={{
                padding: '12px 24px',
                borderRadius: borderRadius,
                border: variant === 'outline' ? `2px solid ${theme.palette.background.paper}` : `2px solid ${theme.palette.primary.main}`,
                backgroundColor: variant === 'normal' ? theme.palette.primary.main : 'transparent',
                color: theme.palette.background.paper,
                textTransform: 'none',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                    color: variant === 'normal' ? theme.palette.primary.main : theme.palette.primary.main,
                    backgroundColor: variant === 'normal' ? theme.palette.background.paper : theme.palette.background.paper,
                    border: variant === 'normal' ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.background.paper}`,
                },
                '&:active': {
                    transform: 'scale(0.98)',
                },
                '&:disabled': {
                    color: theme.palette.text.disabled,
                    backgroundColor: '#CFCFCF',
                    border: variant === 'normal' ? 'none' : `2px solid ${theme.palette.text.disabled}`,
                },
                ...sx,
            }}
        >
            <Typography
                variant='button'>
                {label}
            </Typography>
        </Button >
    );
};

export default ButtonComponent;
