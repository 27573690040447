import React, { useState } from 'react';

// MUI Components
import { Box, AppBar, Toolbar, IconButton, Link, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';

// Assets
import hoveniLogo from "../../assets/hoveni-logo.svg";
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    // State for handling mobile drawer (menu)
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    // List of links
    const navLinks = [
        { label: 'Home', href: '/#header', target: '_self' },
        { label: 'Services', href: '/#services', target: '_self' },
        { label: 'About', href: '/#about-us', target: '_self' },
        { label: 'Contact', href: '/#contact-us', target: '_self' },
    ];

    return (
        <AppBar elevation={0}
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                backdropFilter: 'blur(10px)',
                color: theme.palette.text.secondary,
                borderRadius: isMobile ? 0 : '100px',
                boxShadow: `0 30px 60px 0 rgba(27, 31, 10, 0.08)`,
                top: isMobile ? '0px' : '16px',
                position: isMobile ? "relative" : "sticky",
                paddingX: '0px !important',

                width: '100%',
                maxWidth: {
                    xs: '100%', // Mobile
                    sm: '900px', // Tablet
                    md: '1400px', // Desktop
                },
                margin: isMobile ? '0 auto' : '3rem auto 0rem auto',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 90,
                    padding: '0 1rem',
                }}
            >
                {/* Logo */}
                <Box
                    p={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 'auto',
                        borderRadius: theme.shape.borderRadius,
                        transition: 'all 0.3s ease',

                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: theme.palette.primary.dark,
                        },

                        '&:active': {
                            transform: 'scale(0.95)',
                        },
                    }}
                    onClick={() => navigate('/')}
                >
                    <img
                        src={hoveniLogo}
                        alt="Hoveni Logo"
                        style={{
                            maxHeight: isMobile ? '48px' : '60px',
                            width: 'auto',
                        }}
                    />
                </Box>


                {/* Desktop Links */}
                {!isMobile && (
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        {navLinks.map((link) => (
                            <Link
                                key={link.label}
                                href={link.href}
                                target={link.target}
                                color="inherit"
                                underline="none"
                                sx={{
                                    fontSize: '1.4rem',
                                    lineHeight: '1.5',
                                    fontWeight: 500,
                                    padding: '16px',
                                    borderRadius: theme.shape.borderRadius,
                                    transition: 'all 0.3s ease-in-out',

                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                        textDecoration: 'none',
                                    },

                                    '&:active': {
                                        transform: 'scale(0.90)',
                                    },
                                }}
                            >
                                {link.label}
                            </Link>
                        ))}
                    </Box>
                )}

                {/* Mobile Hamburger Menu */}
                {isMobile && (
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                )}

                {/* Mobile Drawer */}
                <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                    <Box
                        sx={{ width: 250, display: 'flex', flexDirection: 'column', height: '100%' }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <IconButton onClick={toggleDrawer(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <List>
                            {navLinks.map((link) => (
                                <ListItem key={link.label} component="a" href={link.href} target={link.target}
                                    sx={{
                                        lineHeight: '1.5',
                                        padding: '16px',
                                        transition: 'all 0.3s ease-in-out',

                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.dark,
                                            textDecoration: 'none',
                                        },
                                    }}
                                >
                                    <ListItemText primary={link.label} primaryTypographyProps={{
                                        fontSize: '1.4rem',
                                        fontWeight: 500,
                                    }} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
