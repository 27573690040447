import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";

export const bookHomestay = async (
  homestayID: string,
  activitiesIDs: string[],
  guest_name: string,
  guest_mobile: string,
  fromDate: string,
  toDate: string,
  guests: number
) => {
  let uri: string = `/booking/create`;

  try {
    const response = await axiosInstance.post(uri, {
      homestay_id: homestayID,
      activities_id: activitiesIDs,
      guest_name,
      guest_mobile,
      from_date: fromDate,
      to_date: toDate,
      number_of_guests: guests,
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data.error || "Booking failed");
    } else {
      toast.error("An unexpected error occurred");
    }
    throw error;
  }
};
