import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

interface OverlaySpinnerProps {
    open: boolean;
}

const StyledBackdrop = styled(Backdrop)({
    color: '#fff',
    zIndex: 1301, // MUI default zIndex for Backdrop
});

const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({ open }) => {
    return (
        <StyledBackdrop open={open}>
            <CircularProgress color="inherit" />
        </StyledBackdrop>
    );
};

export default OverlaySpinner;
