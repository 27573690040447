import React, { useState } from 'react';
import {
    Box, Card, CardContent, CardActionArea, Typography,
    CardMedia, IconButton, Chip, Stack, CircularProgress
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useTheme } from "@mui/material/styles";
import { Homestay } from '../../types';
import { toast } from "react-toastify";
import copy from 'copy-to-clipboard';

// Assets
import ShareIcon from '@mui/icons-material/Share';
import locationIcon from "../../assets/location-icon.svg";
import StarIcon from '@mui/icons-material/Star';
import { sortImagePaths } from '../../services/homestayService';

interface HomestayCardProps {
    homestay: Homestay | undefined;
    onClick?: () => void;
}

const HomestayCard: React.FC<HomestayCardProps> = ({ homestay, onClick }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean[]>(Array(homestay?.images.length).fill(true)); // Array to track loading states of each image

    // Return null if homestay is undefined
    if (!homestay) {
        return null;
    }

    const shareHomestay = () => {
        const homestayUrl = new URL(`/homestay/${homestay.id}`, window.location.origin).toString();

        if (navigator.share) {
            // Use Web Share API if available
            navigator
                .share({
                    title: homestay?.name || "Hoveni Homestay",
                    text: `Check out this homestay on Hoveni: ${homestay?.name || ""}`,
                    url: homestayUrl,
                })
                .then(() => {
                    toast.success("Shared successfully");
                })
                .catch((error) => {
                    toast.error("Failed to share");
                });
        } else if (navigator.clipboard) {
            // Fallback: Copy URL to clipboard if Web Share API is not supported
            navigator.clipboard.writeText(homestayUrl)
                .then(() => {
                    toast.success("Link copied to clipboard");
                })
                .catch(() => {
                    toast.error("Failed to copy the link");
                });
        } else {
            copy(homestayUrl);
            toast.success("Link copied to clipboard");
        }
    }



    const onCardClick = () => {
        window.location.href = `/homestay/${homestay.id}`;
    }

    const handleImageLoad = (index: number) => {
        setLoading((prevState) => {
            const newLoadingState = [...prevState];
            newLoadingState[index] = false; // Set loading to false for the specific image
            return newLoadingState;
        });
    };

    return (
        <>
            <Card
                sx={{
                    maxWidth: 400,
                    borderRadius: 4,
                    boxShadow: '0px 8px 15px 0px rgba(27, 31, 10, 0.08)',
                    transition: 'all 0.1s ease',
                    border: '1px solid #ffffff',

                    '&:hover': {
                        border: '1px solid #5486E9',
                    },
                }}>
                <Box>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop={true}
                        showStatus={false}
                        autoPlay={false}
                        interval={3000}
                        swipeScrollTolerance={10}
                        preventMovementUntilSwipeScrollTolerance={true}
                    >
                        {sortImagePaths(homestay.images).slice(0, 4).map((image, index) => (
                            <div key={index}>
                                {loading[index] && <CircularProgress />}
                                <CardMedia
                                    component="img"
                                    height="300"
                                    image={image}
                                    alt={`Homestay Image ${index + 1}`}
                                    style={{ display: loading[index] ? 'none' : 'block' }}
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageLoad(index)}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Box>
                <CardActionArea onClick={onCardClick} sx={{ height: '100%' }}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            height: '100%',
                        }}
                    >
                        {/* Title */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                gutterBottom
                                variant="h3"
                                component="div"
                                m={0}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                }}
                            >
                                {homestay.name}
                            </Typography>

                            <IconButton
                                edge="end"
                                aria-label="share"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    color: theme.palette.text.primary,
                                    margin: 0,
                                    borderRadius: theme.shape.borderRadius,
                                    transition: "all 0.3s ease",
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                }}
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevents the onCardClick from being triggered
                                    shareHomestay();
                                }}
                            >
                                <ShareIcon fontSize='small' />
                            </IconButton>
                        </Box>

                        {/* Location & Rating */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <img src={locationIcon} alt="location icon" style={{ width: 'auto', height: '20px' }} />
                                <Typography variant="caption" color="#8F908F">{homestay?.location_short}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1,
                                }}>
                                <StarIcon color="warning" fontSize='small' />
                                <Typography variant="caption" fontWeight="500">{homestay?.rating}</Typography>
                            </Box>
                        </Box>

                        {/* Activities */}
                        {
                            homestay.activities && homestay.activities.length > 0 && ( // Check if activities exist and are not empty
                                <Stack direction="row" spacing={1}>
                                    {
                                        homestay.activities.map((activity, index) => (
                                            <Chip label={activity.name} key={index} variant="outlined" sx={{
                                                fontSize: '12px',
                                            }} />
                                        ))
                                    }
                                </Stack>
                            )
                        }

                        {/* Description */}
                        <Typography variant="body1"
                            color="text.secondary"
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}
                        >
                            {homestay?.description}
                        </Typography>

                        {/* Pricing */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >

                            {homestay?.prices[0].discounted_price > 0 ? (
                                <>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: '1.5rem',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                        }}
                                    >
                                        ₹{homestay?.prices[0].discounted_price}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        fontWeight="500"
                                        sx={{
                                            textDecoration: 'line-through',
                                            color: theme.palette.text.disabled,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                        }}
                                    >
                                        ₹{homestay?.prices[0].base_price}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                                    ₹{homestay?.prices[0].base_price}
                                </Typography>
                            )}

                        </Box>
                    </CardContent>
                </CardActionArea>

            </Card >
        </>
    )
}

export default HomestayCard;