import React, { useState, useEffect, useRef } from 'react';

// MUI & Components
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery, Container, Typography, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import ContentMargin from '../../components/ContentMargin/ContentMargin';
import Navbar from '../../components/Navbar/Navbar';
import GoToTopButton from "../../components/GoToTopButton/GoToTopButton";
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent';
import ImageCollage from '../../components/ImageCollage/ImageCollage';
import OSMMap from '../../components/OSMMap/OSMMap';

// Assets
import serviceCard1Img from "../../assets/images/service-card-1.jpg"
import serviceCard2Img from "../../assets/images/service-card-2.jpg"
import serviceCard3Img from "../../assets/images/service-card-3.jpg"

import icon1 from "../../assets/images/icon-1.png"
import icon2 from "../../assets/images/icon-2.png"
import icon3 from "../../assets/images/icon-3.png"

import aboutUsImg from "../../assets/images/about-us.jpg"
import SearchBar from '../../components/SearchBar/SearchBar';


const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const location = useLocation();

    const headerRef = useRef<HTMLDivElement | null>(null);
    const servicesRef = useRef<HTMLDivElement | null>(null);
    const whyHoveniRef = useRef<HTMLDivElement | null>(null);
    const howHoveniRef = useRef<HTMLDivElement | null>(null);
    const galleryRef = useRef<HTMLDivElement | null>(null);
    const aboutUsRef = useRef<HTMLDivElement | null>(null);
    const contactUsRef = useRef<HTMLDivElement | null>(null);

    const imageArray = [
        { src: 'gallery-1.jpg', isLocal: true },
        { src: 'gallery-2.jpg', isLocal: true },
        { src: 'gallery-3.jpg', isLocal: true },
        { src: 'gallery-4.jpg', isLocal: true },
        { src: 'gallery-5.jpg', isLocal: true },
        { src: 'gallery-6.jpg', isLocal: true },
        { src: 'gallery-7.jpg', isLocal: true },
        { src: 'gallery-8.jpg', isLocal: true },
        { src: 'gallery-9.jpg', isLocal: true },
    ];

    const [colorToggle, setColorToggle] = useState(false);

    useEffect(() => {
        const hash = location.hash.substring(1);
        sessionStorage.removeItem('searchData');

        if (hash) {
            scrollToSection(hash);
        }

        // Toggle color every 5 seconds
        const interval = setInterval(() => {
            setColorToggle(prev => !prev); // Toggle the state
        }, 3500);

        // Clean up the interval when component unmounts
        return () => clearInterval(interval);
    }, [location.hash]);

    const scrollToSection = (sectionId: string) => {
        // Using switch instead of multiple if conditions
        switch (sectionId.toLowerCase()) {
            case 'header':
                headerRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'services':
                servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'why-hoveni':
                whyHoveniRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'how-hoveni':
                howHoveniRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'gallery':
                galleryRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'about-us':
                aboutUsRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'contact-us':
                contactUsRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                // Optionally scroll to the top or default section
                headerRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: theme.palette.background.default,
            }} >
            <Navbar />
            <Box
                component="main"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >

                {/* Section A - Header*/}
                <Container
                    id="header"
                    ref={headerRef}
                    sx={{
                        paddingTop: isMobile ? 8 : 12,
                    }}
                >
                    <ContentMargin
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: 4,
                                height: 'auto',
                                width: isMobile ? '100%' : '80%',
                            }}>

                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: isMobile ? '3.5rem' : '5rem',
                                }}
                            >
                                Experiences. Homestays.
                            </Typography>

                            <SearchBar />

                            {
                                !isMobile && (
                                    <Typography
                                        variant="body2"
                                    >
                                        Discover <u>unique</u> and <u>authentic stays</u> where each homestay connects you to the local culture.
                                        Whether you're looking relax in the mountains or an exciting cultural experiences.
                                    </Typography>
                                )
                            }

                            <Typography
                                variant="body2"
                            >
                                <b>Hoveni</b> links you with welcoming hosts who offer more than just a place to stay —
                                they invite you into their <u>culture</u>, <u>tradition</u>, and <u>experiences</u>.
                                Start your journey now — book your unforgettable <b>Hoveni</b> stay today.
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: 1,
                                marginBottom: isMobile ? 4 : 8,
                            }}>
                                <Typography
                                    variant="h2"
                                    sx={{ fontWeight: '500' }}
                                >
                                    Try booking your
                                </Typography>
                                <Typography variant="h2" sx={{
                                    fontWeight: '700',
                                    transition: 'color 1s ease-in-out',
                                    color: colorToggle ? theme.palette.primary.main : 'inherit',

                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                    }
                                }}>
                                    Stay Now!
                                </Typography>
                            </Box>


                        </Box>
                    </ContentMargin>
                </Container>

                {/* Section B - Our Services*/}
                <Box
                    id="services"
                    ref={servicesRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                        alignItems: 'center',
                        maxWidth: 'unset',
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <ContentMargin>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: 4,
                                height: 'auto',
                                color: theme.palette.background.paper
                            }}>

                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.background.paper,
                                    fontSize: isMobile ? '3.5rem' : '5rem',
                                }}
                            >
                                Our Services
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    paddingX: isMobile ? 4 : 0
                                }}
                            >
                                Discover the essence of local culture through curated experiences, <br />
                                authentic stays, and seamless bookings, tailored to make your <br /> journey unforgettable.
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    width: '100%',
                                }}
                            >



                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        height: '440px',
                                        width: isMobile ? '95vw' : '400px',
                                        minWidth: '300px',
                                        borderRadius: '24px',
                                        boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.08)',
                                        transition: 'all 0.3s ease-in-out',

                                        '&:hover': {
                                            boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.16)',
                                            transform: 'scale(1.02)',
                                        }
                                    }}>
                                    <img src={serviceCard1Img} alt="img1"
                                        style={{ width: '100%', height: '50%', objectFit: 'cover', borderTopLeftRadius: '24px', borderTopRightRadius: '24px' }}
                                    />

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Charming & Lovely Homestays
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            paddingBottom={2}
                                        >
                                            Discover homestays that reflect the local spirit, offering you an authentic and memorable experience.
                                        </Typography>
                                    </Box>

                                </Card>


                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        height: '440px',
                                        width: isMobile ? '95vw' : '400px',
                                        minWidth: '300px',
                                        borderRadius: '24px',
                                        boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.08)',
                                        transition: 'all 0.3s ease-in-out',

                                        '&:hover': {
                                            boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.16)',
                                            transform: 'scale(1.02)',
                                        }
                                    }}>
                                    <img src={serviceCard2Img} alt="img2"
                                        style={{ width: '100%', height: '50%', objectFit: 'cover', borderTopLeftRadius: '24px', borderTopRightRadius: '24px' }}
                                    />

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Majestic Mountain Adventures
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            paddingBottom={2}>
                                            Breathe in fresh air and enjoy stunning vistas with a mountain homestay experience.
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        height: '440px',
                                        width: isMobile ? '95vw' : '400px',
                                        minWidth: '300px',
                                        borderRadius: '24px',
                                        boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.08)',
                                        transition: 'all 0.3s ease-in-out',

                                        '&:hover': {
                                            boxShadow: '0px 30px 60px 15px rgba(27, 31, 10, 0.16)',
                                            transform: 'scale(1.02)',
                                        }
                                    }}>
                                    <img src={serviceCard3Img} alt="img3"
                                        style={{ width: '100%', height: '50%', objectFit: 'cover', borderTopLeftRadius: '24px', borderTopRightRadius: '24px' }}
                                    />

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Cultural Activities & Events
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            paddingBottom={2}
                                        >
                                            Immerse yourself in the local culture with curated activities and events tailored to showcase its traditions.
                                        </Typography>
                                    </Box>

                                </Card>



                            </Box>

                        </Box>
                    </ContentMargin>
                </Box>


                {/* Section C - Why Hoveni?*/}
                <Container
                    id="why-hoveni"
                    ref={whyHoveniRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                    }}
                >
                    <ContentMargin>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: 4,
                                height: 'auto',
                            }}>

                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: isMobile ? '3.5rem' : '5rem',
                                }}
                            >
                                Why Hoveni?
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    alignItems: isMobile ? 'center' : 'flex-start',
                                    gap: isMobile ? 6 : 4,
                                    width: '100%',
                                    paddingTop: 6,
                                }}
                            >

                                <Card sx={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    maxWidth: '440px',
                                    height: 'auto',
                                }} >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '50%',
                                            backgroundColor: theme.palette.primary.main
                                        }}
                                    >
                                        <img src={icon1} alt="icon1" style={{ width: '80px', height: '80px' }} />
                                    </Box>

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            maxWidth: '250px',
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Immersive Local Culture
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                        >
                                            Experience the essence of the local culture through engaging
                                            activities and genuine interactions with our hosts.
                                            Each stay is a deep dive into the traditions, stories, and unique charm of the region.
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card sx={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    maxWidth: '440px',
                                    height: 'auto',
                                }} >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '50%',
                                            backgroundColor: theme.palette.primary.main
                                        }}
                                    >
                                        <img src={icon2} alt="icon2" style={{ width: '80px', height: '80px' }} />
                                    </Box>

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            maxWidth: '250px',
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Personalized Host Connections
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                        >
                                            Our hosts are not just providers of accommodation; they are your local guides, eager to share
                                            their knowledge and create memorable experiences.
                                            From home-cooked meals to insider tips, our hosts make every stay personal and enriching.
                                        </Typography>
                                    </Box>

                                </Card>


                                <Card sx={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    maxWidth: '440px',
                                    height: 'auto',
                                }} >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '50%',
                                            backgroundColor: theme.palette.primary.main
                                        }}
                                    >
                                        <img src={icon3} alt="icon3" style={{ width: '80px', height: '80px' }} />
                                    </Box>

                                    <Box
                                        px={2}
                                        sx={{
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            maxWidth: '250px',
                                        }}
                                    >
                                        <Typography
                                            variant="h2">
                                            Authentic and Unique Stays
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                        >
                                            Discover homestays that reflect the true spirit of the area. Each accommodation is
                                            handpicked to offer an authentic, comfortable,
                                            and unforgettable experience that goes beyond traditional lodging.
                                        </Typography>
                                    </Box>

                                </Card>
                            </Box>

                        </Box>
                    </ContentMargin>
                </Container>

                {/* Section D - How is Hoveni different? */}
                <Box
                    id="how-hoveni"
                    ref={howHoveniRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                        alignItems: 'center',
                        maxWidth: 'unset',
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <ContentMargin>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                gap: 4,
                                height: 'auto',
                                color: theme.palette.background.paper
                            }}>

                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.background.paper,
                                    fontSize: isMobile ? '3.5rem' : '5rem',
                                }}
                            >
                                How is Hoveni <br /> different?
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    paddingX: isMobile ? 8 : 16
                                }}
                            >
                                <b>Hoveni</b> offers more than just a place to stay; we offer a journey into the local culture.
                                Our carefully selected hosts ensure that your stay is filled with unique
                                experiences and personal connections, making every trip unforgettable.
                            </Typography>

                            <ButtonComponent
                                variant="outline"
                                label="Learn more"
                                onClick={() => scrollToSection("services")}
                            />

                        </Box>
                    </ContentMargin>
                </Box>

                {/* Section E - About Us */}
                <Box
                    id="about-us"
                    ref={aboutUsRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                        alignItems: 'center',
                        maxWidth: 'unset',
                        width: '100%',
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <ContentMargin>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                width: '100%',
                                height: 'auto',
                                overflow: 'hidden',
                                gap: 4,
                            }}
                        >
                            {/* Left Side - Text */}
                            <Box
                                sx={{
                                    flex: 1,
                                    padding: '16px',
                                    display: 'flex',
                                    gap: 4,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: isMobile ? '3.5rem' : '5rem',
                                    }}
                                >
                                    About Us
                                </Typography>

                                <Typography
                                    variant="body2"
                                >
                                    When you choose Hoveni, you're not just booking a room - you're connecting with a welcoming host.
                                    Our hosts are the heart and soul of our platform, eager to share their local knowledge, stories,
                                    and traditions. Whether it's a home-cooked meal shared with your host or a guided tour of hidden gems,
                                    these personal touches create a richer travel experience you won't find anywhere else.
                                </Typography>

                                <ButtonComponent
                                    variant="normal"
                                    label="Learn more"
                                    onClick={() => scrollToSection("services")}
                                />
                            </Box>

                            {/* Right Side - Image */}
                            <Box
                                sx={{
                                    flex: 1.5,
                                    display: 'flex',
                                    height: 'auto',
                                    alignItems: 'center',
                                    position: 'relative', // Required for responsive behavior
                                    paddingX: { xs: 2, md: 2, lg: 0 },
                                }}
                            >
                                <img
                                    src={aboutUsImg}
                                    alt="right-image"
                                    style={{
                                        width: '100%',
                                        height: '75%',
                                        objectFit: 'cover',
                                        borderRadius: '16px',
                                    }}
                                />
                            </Box>
                        </Box>

                    </ContentMargin>
                </Box>

                {/* Section F - Gallery */}
                <Box
                    id="gallery"
                    ref={galleryRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                        alignItems: 'center',
                        maxWidth: 'unset',
                        width: '100%',
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <ContentMargin>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 4,
                            }}>

                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: isMobile ? '3.5rem' : '5rem',
                                    textAlign: 'center',
                                }}
                            >
                                Gallery to our <br /> Destinations
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    paddingX: isMobile ? 8 : 16,
                                    textAlign: 'center',
                                }}
                            >
                                Explore stunning visuals of our unique homestays and <br />picturesque landscapes that await your visit.
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    paddingX: { xs: 2, md: 2, lg: 0 },
                                }}
                            >

                                <ImageCollage images={imageArray} />


                            </Box>
                        </Box>

                    </ContentMargin>
                </Box>

                {/* Section G - Contact Us */}
                <Box
                    id="contact-us"
                    ref={contactUsRef}
                    sx={{
                        paddingY: isMobile ? 8 : 12,
                        alignItems: 'center',
                        maxWidth: 'unset',
                        width: '100%',
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <ContentMargin
                    >

                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: isMobile ? '3.5rem' : '5rem',
                                textAlign: 'center',
                                marginBottom: 8,
                            }}
                        >
                            Contact Us
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                height: 'auto',
                                overflow: 'hidden',
                                gap: 4,
                                paddingX: { xs: 2, md: 2, lg: 0 },
                            }}
                        >
                            {/* Left Side - Text */}
                            <Box
                                sx={{
                                    flex: 2,
                                    padding: '24px',
                                    display: 'flex',
                                    gap: 4,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: '16px',
                                }}
                            >

                                <Typography
                                    variant="h2">
                                    Get in Touch
                                </Typography>

                                <Typography
                                    variant="body2"
                                >
                                    <ul style={{ listStyle: 'none', padding: 0, margin: 0, lineHeight: 1.8 }}>

                                        <li>Mobile: <a
                                            href="tel:+917908301284" style={{ color: theme.palette.primary.main }}>+91
                                            7908-301-284</a>
                                        </li>

                                        <li>
                                            WhatsApp: <a href="https://wa.me/917908301284" style={{ color: theme.palette.primary.main }}
                                            >+91 7908-301-284</a>
                                        </li>

                                        <li>
                                            Email: <a href="mailto:contact@hoveni.com" style={{ color: theme.palette.primary.main }}
                                            >contact@hoveni.com</a>
                                        </li>

                                    </ul>
                                </Typography>

                                <Typography
                                    variant="body2"
                                >
                                    <ul style={{ listStyle: 'none', padding: 0, margin: 0, lineHeight: 1.8 }}>

                                        <li>Address:</li>
                                        <li>Park Location, Kurseong</li>
                                        <li>Darjeeling, West Bengal, India</li>
                                        <li>Working hours:</li>
                                        <li>10:00 - 17:00</li>

                                    </ul>
                                </Typography>

                            </Box>

                            {/* Right Side - Map */}
                            <Box
                                sx={{
                                    flex: 3,
                                    display: 'flex',
                                    height: 'auto',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '16px',
                                    minHeight: '400px',
                                }}
                            >
                                <OSMMap latitude={26.879380} longitude={88.276043} zoom={16} tooltip='Hoveni Office, Kurseong' radius={100} />
                            </Box>
                        </Box>

                    </ContentMargin>
                </Box>

                <GoToTopButton />

            </Box>
            <Footer />
        </Box >
    );
};

export default Home;
