import React, { useState, useEffect } from 'react';
import {
    Box,
    ImageList,
    ImageListItem,
    Dialog,
    IconButton,
    CircularProgress
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Close, ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';

interface ImageCollageProps {
    images: Array<{ src: string; title?: string; isLocal: boolean }>;
    view?: 'grid' | 'slider';
    sx?: React.CSSProperties;
}

const ImageCollage: React.FC<ImageCollageProps> = ({ images, view = 'grid', sx }) => {
    const [open, setOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    const [cols, setCols] = useState(4); // Default number of columns
    const [loading, setLoading] = useState<boolean[]>(Array(images.length).fill(true));

    const isMobile = useMediaQuery('(max-width:767px)');

    // Adjust the number of columns dynamically based on screen size
    const handleResize = () => {
        if (window.innerWidth < 600) {
            setCols(1); // Mobile view
        } else if (window.innerWidth < 900) {
            setCols(2); // Small tablet view
        } else if (window.innerWidth < 1200) {
            setCols(3); // Medium tablet view
        } else {
            setCols(4); // Desktop view
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleClickOpen = (index: number) => {
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImageIndex(0);
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const getImageSrc = (image: { src: string; isLocal: boolean }) => {
        return image.isLocal ? require(`../../assets/images/${image.src}`) : image.src;
    };

    const handleImageLoad = (index: number) => {
        setLoading((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[index] = false; // Set image as loaded
            return newLoading;
        });
    };

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    return (
        <>
            <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', borderRadius: '16px',
                ...sx
            }}
            >

                {view === 'grid' ? (
                    // Grid view
                    <ImageList
                        variant="quilted"
                        cols={cols}
                        gap={8}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: isMobile ? 'flex' : 'grid',
                            flexDirection: isMobile ? 'column' : 'unset',
                        }}
                    >
                        {images.map((image, index) => (
                            <ImageListItem
                                key={index}
                                cols={index % 3 === 0 ? 2 : 1}
                                rows={index % 3 === 0 ? 2 : 1}
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                {loading[index] && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 1,
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}

                                <img
                                    {...srcset(getImageSrc(image), 400, 2, 2)}
                                    alt={image.title}
                                    loading="lazy"
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '16px',
                                    }}
                                    onClick={() => handleClickOpen(index)}
                                    onLoad={() => handleImageLoad(index)}
                                    onContextMenu={(e) => e.preventDefault()}
                                    draggable={false}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                ) : (
                    // Slider view (horizontal scroll)
                    <Box sx={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', gap: 1 }}>
                        {images.map((image, index) => (
                            <ImageListItem
                                key={index}
                                sx={{
                                    flex: '0 0 auto',
                                    width: 300, // Adjust width as needed
                                    height: 200, // Adjust height as needed
                                    position: 'relative',
                                }}
                            >
                                {loading[index] && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 1,
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}

                                <img
                                    src={getImageSrc(image)}
                                    alt={image.title}
                                    loading="lazy"
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '16px',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    onClick={() => handleClickOpen(index)}
                                    onLoad={() => handleImageLoad(index)}
                                    onContextMenu={(e) => e.preventDefault()}
                                    draggable={false}
                                />
                            </ImageListItem>
                        ))}
                    </Box>
                )}

                {/* Modal to show full image */}
                < Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {/* Close Button */}
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',

                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }}
                        >
                            <Close />
                        </IconButton>

                        {/* Previous Button */}
                        <IconButton
                            onClick={handlePrev}
                            sx={{
                                position: 'absolute',
                                left: 10,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            }}
                        >
                            <ArrowBackIosNew />
                        </IconButton>

                        {/* Full-size image */}
                        {images[currentImageIndex] && (
                            <img
                                src={getImageSrc(images[currentImageIndex])}
                                alt={images[currentImageIndex].title}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '90vh',
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                                draggable={false}
                            />
                        )}

                        {/* Next Button */}
                        <IconButton
                            onClick={handleNext}
                            sx={{
                                position: 'absolute',
                                right: 10,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                },
                            }}
                        >
                            <ArrowForwardIos />
                        </IconButton>
                    </Box>
                </Dialog >
            </Box >
        </>
    );
};

export default ImageCollage;
