import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import OverlaySpinner from "./components/OverlaySpinner/OverlaySpinner";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={<OverlaySpinner open={true} />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
