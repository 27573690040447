import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home/Home';

import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import theme from './theme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomestayPage from './pages/HomestayPage/HomestayPage';
import SearchResults from './pages/SearchResults/SearchResults';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import GoogleCallback from './pages/Callbacks/GoogleCallback';

function App() {
  // Media query to handle responsive design
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/search/:query" element={<SearchResults />} />
          <Route path="/homestay/:id" element={<HomestayPage />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path='*' element={<PageNotFound />} />

        </Routes>
        <ToastContainer position={isMobile ? 'top-right' : 'top-center'} autoClose={1800} limit={5} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
