import React, { useState } from 'react';
import {
    Dialog,
    Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    useTheme,
    useMediaQuery,
    TextField,
    Accordion, AccordionSummary, AccordionDetails,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { toast } from 'react-toastify';
import { bookHomestay } from '../../services/bookingServices';
import { Activity } from "../../types";
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { resetState } from "../../store/activitiesSlice";

// Assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import hoveniLogo from "../../assets/hoveni-logo.svg";
import ButtonComponent from '../ButtonComponent/ButtonComponent';

interface BookingDialogProps {
    open: boolean;
    onClose: () => void;
    isSuccess: (data: boolean) => void;
    homestayID: string;
    activitiesIDs: string[];
}

interface AddedActivity {
    activity: Activity;
    homestayId: string;
}

const BookingDialog: React.FC<BookingDialogProps> = ({
    open, onClose,
    homestayID,
    isSuccess
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    // State variables to hold input values
    const [fullName, setFullName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const selectActivitiesByHomestayId = (state: RootState, homestayId: string): AddedActivity[] => {
        return state.activities.activities.filter(
            (activity: AddedActivity) => activity.homestayId === homestayId
        );
    };

    const activities = useSelector((state: RootState) => selectActivitiesByHomestayId(state, homestayID));

    const handleBooking = async () => {
        try {
            // Get booking data from session storage
            let parsedData;
            const bookingData = sessionStorage.getItem('bookingData');
            if (bookingData) {
                parsedData = JSON.parse(bookingData);
            }

            // Get the list of activities from store
            const activityIds = activities.map(item => item.activity.id);

            await bookHomestay(
                homestayID,
                activityIds,
                fullName,
                mobileNumber,
                parsedData.checkInDate,
                parsedData.checkOutDate,
                parsedData.guests
            );

            toast.success('Booking successful');
            isSuccess(true);

            // Remove query from URL
            window.history.replaceState({}, document.title, window.location.pathname + window.location.search.replace("?loggedIn=true", "").replace("&loggedIn=true", ""));

            // Reset state
            dispatch(resetState());

            onClose();
        } catch (error: any) {
            // Do nothing, since error is toasted in inner function
            isSuccess(false);
        }
    };

    // Handlers to update state on change
    const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFullName(event.target.value);
    };

    const handleMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Allow only numbers and ensure it's a valid 10-digit Indian number
        if (value === '' || /^[6-9]\d{0,9}$/.test(value)) {
            setMobileNumber(value);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black backdrop
                    boxShadow: 'none',
                    maxWidth: isMobile ? '100%' : '600px',
                    minWidth: '360px',
                    maxHeight: isMobile ? '90vh' : '75vh'
                },
            }}
        >
            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{
                        width: isMobile ? '90%' : '400px',
                        padding: 4,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3
                    }}>
                    {/* Close button */}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8, }} // Close button color
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Logo */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'auto',
                        }}
                    >
                        <img
                            src={hoveniLogo}
                            alt="Hoveni Logo"
                            style={{
                                maxHeight: isMobile ? '48px' : '60px',
                                width: 'auto',
                            }}
                        />
                    </Box>

                    <Typography variant="h3" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Let's Get your Homestay booked!
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        Please enter your details to book your homestay.
                        <br />
                        We shall contact you using these details.
                    </Typography>



                    {/* Booking Details Accordion - Activities */}
                    {
                        activities.length > 0 && (
                            <Box>
                                <Accordion
                                    sx={{
                                        boxShadow: 'none',
                                        borderRadius: '8px',
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Activity Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table

                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{ fontSize: '1rem' }}>
                                                            Activity Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            sx={{ fontSize: '1rem' }}>
                                                            Price
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {activities.map((item) => (
                                                        <TableRow key={item.activity.name}>
                                                            <TableCell
                                                                sx={{ fontSize: '1rem' }}>
                                                                {item.activity.name}</TableCell>
                                                            <TableCell
                                                                align="right"
                                                                sx={{ fontSize: '1rem' }}>
                                                                ₹
                                                                {
                                                                    item.activity?.prices[0].discounted_price > 0 ? (
                                                                        item.activity.prices[0].discounted_price
                                                                    ) : (
                                                                        item.activity.prices[0].base_price
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    }

                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, padding: 0 }}>

                        {/* Input fields */}
                        <TextField
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={fullName}
                            onChange={handleFullNameChange}
                        />

                        <TextField
                            label="Mobile Number"
                            variant="outlined"
                            fullWidth
                            required
                            type="tel"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            inputProps={{
                                maxLength: 15, // Limit maximum length
                                pattern: "[0-9]*", // Only allow numbers on mobile devices
                            }}
                        />

                        <ButtonComponent
                            label="Book Homestay"
                            variant="normal"
                            onClick={handleBooking}
                            sx={{ width: '100%' }}
                            disabled={fullName === '' || mobileNumber.length < 10}
                        />

                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
};

export default BookingDialog;
