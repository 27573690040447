import React, { useState } from 'react';
import {
    Box,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActionArea,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    Button,
    useMediaQuery,
    Table, TableBody, TableCell, TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageCollage from '../../components/ImageCollage/ImageCollage';
import { useTheme } from "@mui/material/styles";

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addActivity, removeActivity } from '../../store/activitiesSlice';
import { AppDispatch, RootState } from '../../store';
import { Activity } from '../../types';
import { toast } from "react-toastify";
import { Carousel } from 'react-responsive-carousel';
import { sortImagePaths } from '../../services/homestayService';

interface ActivityCardProps {
    activity: Activity | undefined;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const existingActivities = useSelector((state: RootState) => state.activities.activities);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Return null if the activity is not found
    if (!activity) {
        return null;
    }

    const formatImageArray = (num?: number) => {
        const images = (activity?.images)?.map((image) => {
            return { src: image, isLocal: false };
        }) || [];

        return num ? images.slice(0, num) : images;
    };

    // Check if the activity is already added for the homestay
    const isActivityAdded = existingActivities.some(
        (item) => item.activity.id === activity.id && item.homestayId === id
    );

    const handleAddRemoveActivity = () => {
        if (activity && id) {
            if (isActivityAdded) {
                dispatch(removeActivity({ activityId: activity.id, homestayId: id }));
                toast.success("Activity removed from booking.");
            } else {
                dispatch(addActivity({ activity, homestayId: id }));
                toast.success("Activity added to booking successfully.");
            }
        } else {
            toast.error("Failed to update booking. Please try again later.");
        }
    };

    return (
        <>

            {/* Activity Card */}
            <Card
                sx={{
                    height: 440,
                    maxWidth: 400,
                    display: 'flex',
                    borderRadius: theme => theme.shape.borderRadius,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    margin: 0,
                    boxShadow: 'none',
                    transition: 'all 0.1s ease-in-out',
                    border: '1px solid #ffffff',

                    '&:hover': {
                        border: '1px solid #5486E9',
                    }

                }}
            >
                <CardActionArea onClick={handleOpen} sx={{
                    height: '100%',
                }}>
                    <CardMedia
                        component="img"
                        image={(activity?.images)[0]} // Use the first image for the card
                        alt={activity?.name}
                        sx={{ height: 220 }}
                    />
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography
                            variant="h3"
                            component="div"
                            gutterBottom
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}
                        >
                            {activity?.name}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}
                        >
                            {activity?.description}
                        </Typography>
                        <Typography variant="body2" color="text.primary" mt={1} fontWeight={500} fontSize={28}>
                            ₹
                            {
                                activity?.prices[0].discounted_price > 0 ? (
                                    activity.prices[0].discounted_price
                                ) : (
                                    activity?.prices[0].base_price
                                )
                            }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>

            {/* Dialog for more details */}
            <Dialog open={open} onClose={handleClose}

                PaperProps={{
                    sx: {
                        maxWidth: isMobile ? '100%' : '80vw',
                        minWidth: '360px',
                        margin: isMobile ? '8px' : 'auto',
                    },
                }}

            >
                <DialogTitle>
                    <Typography variant="h2" color="text.primary" mt={1} fontWeight={500}>
                        {activity?.name}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            flexWrap: 'nowrap',
                            width: '100%',
                            height: isMobile ? 'auto' : '400px',
                            overflow: 'scroll',
                            borderRadius: 8,
                            gap: 4,
                        }}
                    >
                        {
                            isMobile && activity ? (
                                <Carousel
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    autoPlay={true}
                                    interval={5000}
                                >
                                    {(activity.images).map((image, index) => (
                                        <div key={index} style={{ height: '100%', overflow: 'hidden' }}>
                                            <CardMedia
                                                component="img"
                                                image={image}
                                                alt={`Homestay Image ${index + 1}`}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            ) :
                                (
                                    <ImageCollage images={formatImageArray()} view='slider' sx={{
                                        maxHeight: isMobile ? '300px' : '700px',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }} />
                                )
                        }
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isMobile ? 2 : 4,
                            marginTop: 4,
                        }}>

                        {/* Price */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 4,
                                alignItems: 'center',
                                paddingY: 1,
                                color: theme.palette.text.primary,
                                width: isMobile ? '100%' : 'fit-content',
                                justifyContent: isMobile ? 'space-between' : 'flex-start',
                            }}
                        >

                            <Typography variant="body2" fontWeight={500} fontSize={28}>
                                ₹
                                {
                                    activity?.prices[0].discounted_price > 0 ? (
                                        activity.prices[0].discounted_price
                                    ) : (
                                        activity?.prices[0].base_price
                                    )
                                }
                            </Typography>


                            <Button
                                onClick={handleAddRemoveActivity}
                                sx={{
                                    padding: 1,
                                    fontSize: '1rem',
                                    height: '40px',
                                    marginLeft: 1,
                                    transition: 'all 0.3s ease-in-out',
                                    color: isActivityAdded === true ? '#FF3D3D' : theme.palette.background.paper,
                                    backgroundColor: isActivityAdded === true ? '#FFDBDB' : theme.palette.primary.main,
                                    outline: isActivityAdded === true ? '2px solid #FF3D3D' : '2px solid' + theme.palette.primary.main,
                                    minWidth: '120px',

                                    '&:hover': {
                                        filter: 'brightness(0.9)',
                                    },

                                    '&:active': {
                                        transform: 'scale(0.98)',
                                    }
                                }}
                            >
                                {isActivityAdded ? '- Remove' : '+ Add to Booking'}
                            </Button>
                        </Box>

                        {/* Description */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}>
                            {/* Description */}
                            <Typography variant="h2">
                                Description
                            </Typography>
                            <Typography variant="body1">
                                {activity?.description}
                            </Typography>
                        </Box>

                        {/* Policies */}
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{
                                    textAlign: "left",
                                    marginBottom: 1,
                                }}
                            >
                                Policies
                            </Typography>

                            <Box sx={{ overflowX: 'auto' }}>
                                <Table
                                    sx={{
                                        width: '100%',
                                        borderCollapse: 'collapse',  // Remove default border spacing
                                    }}
                                >
                                    <TableBody>
                                        {activity?.policies.map((policy, index) => (
                                            <TableRow key={index} sx={{
                                                transition: 'all 0.3s ease',
                                                cursor: 'pointer',

                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                }
                                            }}>
                                                {/* Icon and Name */}
                                                <TableCell
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderBottom: 'none',  // No bottom border
                                                        padding: '8px 12px 0px 0px',
                                                    }}
                                                >
                                                    {/*  Icon is disabled for the time-being */}
                                                    {/* <img
                                                        src={amenity.icon}
                                                        alt={amenity.name}
                                                        style={{ width: 24, height: 24, marginRight: 8 }}
                                                    /> */}
                                                    <strong style={{ fontSize: '1.2rem' }}>{policy.title}</strong>
                                                </TableCell>

                                                {/* Description */}
                                                <TableCell
                                                    sx={{
                                                        borderBottom: 'none',  // No bottom border
                                                        padding: '8px 0px',
                                                        fontSize: '1.2rem'
                                                    }}
                                                >
                                                    {policy.description}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>

                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        height: isMobile ? '40px' : 'auto'
                    }}
                >
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ActivityCard;
