import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import {
    Box, useMediaQuery, Grid2, Typography,

} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import ContentMargin from '../../components/ContentMargin/ContentMargin';
import Navbar from '../../components/Navbar/Navbar';
import OverlaySpinner from "../../components/OverlaySpinner/OverlaySpinner";

import { getAllHomestays } from "../../services/homestayService";
import { Homestay } from "../../types";

// Assets
import GoToTopButton from "../../components/GoToTopButton/GoToTopButton";
import HomestayCard from "../../components/HomestayCard/HomestayCard";

const HomestayPage = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [homestayList, setHomestayList] = useState<Homestay[]>([]);
    const [filter, setFilter] = useState<string>("");

    useEffect(() => {
        fetchAllHomestay();

        // Load saved filter from sessionStorage
        const savedSearchData = sessionStorage.getItem('searchData');
        if (savedSearchData) {
            const savedData = JSON.parse(savedSearchData);
            setFilter(savedData.location);
        }

    }, [location.pathname]);

    const fetchAllHomestay = async () => {
        try {
            const response = await getAllHomestays();
            setHomestayList(response);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    // Derived state for filtered and nearby homestays
    const filteredHomestays = homestayList.filter(homestay =>
        homestay.location_short.toLowerCase().includes(filter.toLowerCase())
    );

    const nearbyHomestays = homestayList.filter(homestay =>
        !homestay.location_short.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <Box
            component="main"
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "100vh",
            }}
        >
            <OverlaySpinner open={isLoading} />
            <Navbar />
            <ContentMargin>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        alignItems: "center",
                        minHeight: "100vh",
                        paddingX: { xs: 2, md: 2, lg: 0 },
                        marginBottom: isMobile ? 8 : 12,
                    }}
                >

                    <Typography
                        variant="h1"
                        sx={{
                            textAlign: "left",
                            marginTop: isMobile ? 4 : 8,
                            width: "100%",
                        }}
                    >
                        Homestays
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            width: "100%",
                        }}
                    >
                        <b>Discover Your Perfect Getaway!</b> <br />
                        Explore our selection of available homestays, ready for your next adventure.<br />
                        Browse through the options and find your favorite spot for a memorable weekend getaway!
                    </Typography>

                    {/* Check if the homestayList is empty */}
                    {homestayList.length !== 0 ? (
                        <>
                            {/* Filtered Homestays based on filter value */}
                            {filteredHomestays.length > 0 && (
                                <>
                                    <Typography
                                        variant="h2"
                                        sx={{ textAlign: "center", marginTop: 4 }}
                                    >
                                        Available Homestays
                                    </Typography>
                                    <Grid2 container spacing={4} justifyContent="center">
                                        {filteredHomestays.map(homestay => (
                                            <HomestayCard key={homestay.location_short} homestay={homestay} />
                                        ))}
                                    </Grid2>
                                </>
                            )}

                            {/* Other Nearby Homestays */}
                            {
                                filteredHomestays.length === 0 && filter !== "all" && (
                                    <Typography
                                        variant="body2"
                                        sx={{ textAlign: "center", marginTop: 4 }}
                                    >
                                        Sorry, we currently have <b>no homestays</b> available at <b>your location</b>. Below are some alternatives
                                    </Typography>
                                )
                            }
                            {nearbyHomestays.length > 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 4,
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={{ textAlign: "left", marginTop: 4 }}
                                    >
                                        {
                                            filter !== "all" ? `Other Nearby Homestays` : "All Homestays"
                                        }
                                    </Typography>
                                    <Grid2 container spacing={4} justifyContent="center">
                                        {nearbyHomestays.map(homestay => (
                                            <HomestayCard key={homestay.location_short} homestay={homestay} />
                                        ))}
                                    </Grid2>
                                </Box>
                            )}
                        </>
                    ) : (
                        !isLoading && (
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "center", marginTop: 4 }}
                            >
                                Sorry, we currently have <b>no homestays available</b>
                            </Typography>
                        )
                    )
                    }


                </Box>

                <GoToTopButton />

            </ContentMargin >
            <Footer />
        </Box >
    );
};

export default HomestayPage;