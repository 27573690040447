import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";

export const googleAuthentication = async () => {
  try {
    const response = await axiosInstance.get("/auth/google/login");
    window.location.href = response.data.redirectUrl;
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data.error || "Google login failed");
    } else {
      toast.error("An unexpected error occurred");
    }
    throw error;
  }
};

export const googleAuthenticationCallback = async (
  state: string,
  code: string
) => {
  try {
    const response = await axiosInstance.get(
      `/auth/google/callback?state=` + state + `&code=` + code
    );

    const {
      access_token,
      refresh_token,
      access_token_expires_in,
      refresh_token_expires_in,
    } = response.data;

    // Store tokens in localStorage or a more secure place
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem(
      "access_token_expires_in",
      access_token_expires_in.toString()
    );
    localStorage.setItem(
      "refresh_token_expires_in",
      refresh_token_expires_in.toString()
    );

    toast.success("Login successful!");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data.error || "Login failed");
    } else {
      toast.error("An unexpected error occurred");
    }
    window.location.href = "/";
    throw error;
  }
};

// TODO: A proper isUserAuthenticated endpoint from API
export const verifyAccessToken = async (accessToken: string) => {
  try {
    const response = await axiosInstance.get("/user/details", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data; // return the user details if verification is successful
  } catch (error: any) {
    throw error;
  }
};
