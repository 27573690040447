import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";

export const getAllHomestays = async () => {
  let uri: string = "/homestay/";
  try {
    const response = await axiosInstance.get(uri);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data.error || "Homestays retrieval failed");
    } else {
      toast.error("An unexpected error occurred");
    }
    throw error;
  }
};

export const getHomestay = async (homestay_id: string) => {
  let uri: string = `/homestay/${homestay_id}`;

  try {
    const response = await axiosInstance.get(uri);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data.error || "Homestay retrieval failed");
    } else {
      toast.error("An unexpected error occurred");
    }
    throw error;
  }
};

// Sort the array based on the numeric part of the filenames
export function sortImagePaths(imagePaths: string[]) {
  return imagePaths.sort((a, b) => {
    // Extract the numeric part from the filenames
    const matchA = a.match(/images\/(\d+)_/);
    const matchB = b.match(/images\/(\d+)_/);

    // If there's no match, assign a large number (or any logic that fits your use case)
    const numA = matchA ? parseInt(matchA[1]) : Number.MAX_VALUE;
    const numB = matchB ? parseInt(matchB[1]) : Number.MAX_VALUE;

    // Compare the numeric values
    return numA - numB;
  });
}
