import { useState, useEffect } from 'react';
import {
    Box, MenuItem,
    useTheme,
    useMediaQuery, InputAdornment,
    FormControl, InputLabel, Select,
    Typography
} from '@mui/material';
import ButtonComponent from '../ButtonComponent/ButtonComponent';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

// Assets
import PeopleIcon from '@mui/icons-material/People';
import { toast } from 'react-toastify';


interface BookingBarProps {
    onSendData: (data: any) => void;
}


const BookingBar: React.FC<BookingBarProps> = ({ onSendData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [checkInDate, setCheckInDate] = useState<Dayjs | null>(dayjs());
    const [checkOutDate, setCheckOutDate] = useState<Dayjs | null>(dayjs().add(1, 'day'));
    const [guests, setGuests] = useState(1);

    // Update check-out date whenever check-in date changes
    useEffect(() => {
        // Get Data from session storage
        const savedSearchData = sessionStorage.getItem('searchData');
        if (savedSearchData) {
            const parsedData = JSON.parse(savedSearchData);
            setCheckInDate(dayjs(parsedData.checkInDate));
            setCheckOutDate(dayjs(parsedData.checkOutDate));
            setGuests(parsedData.guests);
        } else {
            const newCheckOutDate = dayjs().add(1, 'day');
            setCheckOutDate(newCheckOutDate);
        }
    }, []);

    // Validation logic: Check-In date should be at least +4 day from current date
    const handleCheckInChange = (newValue: Dayjs | null) => {
        if (newValue && newValue.isBefore(dayjs().add(4, 'day'))) {
            toast.error('Check-in date should be at least +4 day from current date');
            setCheckInDate(dayjs().add(4, 'day'));
        } else {
            setCheckInDate(newValue);
        }
    };

    // Validation logic: Check-out date should be at least +1 day from check-in date
    const handleCheckOutChange = (newValue: Dayjs | null) => {
        if (newValue && checkInDate && newValue.isBefore(checkInDate.add(1, 'day'))) {
            toast.error('Check-out date should be at least +1 day from check-in date');

            // Temporarily clear date to force re-render, then set it to the minimum valid date
            setCheckOutDate(null);
            setTimeout(() => setCheckOutDate(checkInDate.add(1, 'day')), 0);
        } else {
            setCheckOutDate(newValue);
        }
    };

    const handleSubmit = () => {
        // Create an object to store the search data
        const bookingData = {
            checkInDate: checkInDate ? checkInDate.startOf('day').toISOString() : null,
            checkOutDate: checkOutDate ? checkOutDate.startOf('day').toISOString() : null,
            guests,
        };

        // Save the search data to session storage
        sessionStorage.setItem('bookingData', JSON.stringify(bookingData));

        // Send Data
        onSendData(bookingData);
    };

    return (
        <Box
            sx={{
                position: isMobile ? 'relative' : 'sticky',
                top: isMobile ? 0 : 120,
                zIndex: 1000,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? 2 : 1,
                alignItems: isMobile ? 'center' : 'baseline',
                paddingX: isMobile ? 1 : 4,
                paddingBottom: 3,
                paddingTop: 2,
                borderRadius: 4,
                backgroundColor: theme.palette.background.paper,
                boxShadow: `0 30px 60px 0 rgba(27, 31, 10, 0.08)`,
                width: isMobile ? '100%' : '80vw',
                height: isMobile ? '370px' : 'auto',
                maxWidth: {
                    xs: '100%', // Mobile
                    sm: '900px', // Tablet
                    md: '1400px', // Desktop
                },
            }}>

            {
                isMobile &&
                <Typography
                    variant="h3"
                    sx={{
                        width: '100%',
                        textAlign: 'left',
                        mb: 1
                    }}
                >
                    Book your Stay
                </Typography>
            }


            {/* Number of Guests - Dropdown */}
            <FormControl fullWidth sx={{
                flex: isMobile ? '1 1 100%' : '0.5 1 0',
                textAlign: isMobile ? 'left' : 'center',
                width: isMobile ? '100%' : 'auto'
            }}>
                <InputLabel>Guests</InputLabel>
                <Select
                    label="Guests"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value as number)}
                    startAdornment={
                        <InputAdornment position="start">
                            <PeopleIcon />
                        </InputAdornment>
                    }
                >
                    {Array.from({ length: 10 }, (_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            {/* Check-in Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DemoContainer components={['DatePicker']} sx={{
                    flex: isMobile ? '2 1 100%' : '1 1 0',
                    textAlign: isMobile ? 'left' : 'center',
                    width: isMobile ? '100%' : 'auto',
                    overflow: 'initial'
                }}>
                    <DatePicker
                        label="Check In Date"
                        value={checkInDate}
                        onChange={(newValue) => handleCheckInChange(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>

            {/* Check-out Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DemoContainer components={['DatePicker']} sx={{
                    flex: isMobile ? '2 1 100%' : '1 1 0',
                    textAlign: isMobile ? 'left' : 'center',
                    width: isMobile ? '100%' : 'auto',
                    overflow: 'initial'
                }}>
                    <DatePicker
                        label="Check Out Date"
                        value={checkOutDate}
                        slotProps={{ textField: { fullWidth: true } }}
                        onChange={(newValue) => handleCheckOutChange(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>

            {/* Search Button */}
            <ButtonComponent
                label="Book Now"
                onClick={handleSubmit}
                sx={{
                    height: '56px',
                    padding: '0px',
                    flex: isMobile ? '1 1 100%' : '1.5 1 0',
                    width: isMobile ? '100%' : 'auto'
                }}
            />

        </Box>
    );
};

export default BookingBar;
