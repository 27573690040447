import { useTheme } from "@mui/material/styles";
import {
    Box, useMediaQuery, Typography,

} from '@mui/material';
import Footer from '../../components/Footer/Footer';
import ContentMargin from '../../components/ContentMargin/ContentMargin';
import Navbar from '../../components/Navbar/Navbar';
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";



const PageNotFound = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Box
            component="main"
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "100vh",
            }}
        >
            <Navbar />
            <ContentMargin>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100vh",
                        paddingX: { xs: 2, md: 2, lg: 0 },
                        marginBottom: isMobile ? 8 : 12,
                    }}
                >

                    <Typography variant="h1" sx={{ textAlign: "center" }}>
                        Page Not Found
                    </Typography>

                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                        The page you are looking for has been removed or does not exist.
                        <br />
                        We apologize for any inconvenience this may have caused.
                    </Typography>

                    <ButtonComponent
                        variant="normal"
                        label="Go to Home"
                        onClick={() => window.location.href = "/"}
                    />

                </Box>


            </ContentMargin >
            <Footer />
        </Box >
    );
};

export default PageNotFound;