import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import OverlaySpinner from "../../components/OverlaySpinner/OverlaySpinner";

// Services
import { googleAuthenticationCallback, verifyAccessToken } from "../../services/authServices";
import { toast } from "react-toastify";

const GoogleCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const handleGoogleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      const state = params.get("state");

      try {
        if (state && code) {
          const loginResponse = await googleAuthenticationCallback(state, code);

          // Extract access token from login response
          const { access_token } = loginResponse;
          // Verify the access token by fetching user details
          const userData = await verifyAccessToken(access_token);

          if (userData) {
            // Redirect to the last visited page
            let lastVisitedUrl = localStorage.getItem("lastVisitedUrl") + "?loggedIn=true" || "/";
            window.location.href = lastVisitedUrl;
          } else {
            toast.error("Failed to verify user");
          }
        }
      } catch (error: any) {
        // Error is handled in the AuthService
      }
    };

    handleGoogleCallback();
  }, [location.search]);

  return (
    <React.Fragment>
      <div className="content-margin">
        <Navbar />
        <OverlaySpinner open={true} />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GoogleCallback;
