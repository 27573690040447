import React from 'react';

// MUI & Components
import useTheme from '@mui/material/styles/useTheme';
import { Box, Container, Typography, Link, IconButton, useMediaQuery } from '@mui/material';
import { WhatsApp, Instagram, Facebook } from '@mui/icons-material';

const Footer: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const iconsStyle = {
        fontSize: isMobile ? 32 : 40,
    }

    const socialLinks = [
        { icon: <Facebook sx={iconsStyle} />, href: 'https://www.facebook.com/profile.php?id=61563848177837', label: 'Facebook' },
        { icon: <Instagram sx={iconsStyle} />, href: 'https://www.instagram.com/hoveni_stays', label: 'Instagram' },
        { icon: <WhatsApp sx={iconsStyle} />, href: 'https://wa.me/917908301284', label: 'WhatsApp' },
    ];

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.paper,
                py: 12,
                textAlign: 'center',
                boxShadow: `0px 30px 60px 15px rgba(27, 31, 10, 0.08)`,
            }}
        >
            <Container maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: isMobile ? 8 : 4,
            }}>
                {/* Links */}
                <Typography
                    variant="h2"
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: isMobile ? 2 : 4,
                        textAlign: isMobile ? 'center' : 'initial',
                        color: theme.palette.background.paper,
                        fontWeight: 500,
                        transition: 'all 0.3s ease-in-out',
                    }}
                >
                    <Link href="/#services" rel="noopener" color="inherit">
                        Services
                    </Link>
                    <Link href="/#about-us" color="inherit">
                        About
                    </Link>
                    <Link href="/#gallery" color="inherit">
                        Gallery
                    </Link>

                    <Link href="/#contact-us" color="inherit">
                        Contact
                    </Link>
                </Typography>

                {/* Social Links */}
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                    {socialLinks.map((social, index) => (
                        <IconButton
                            key={index}
                            component="a"
                            href={social.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={social.label}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                color: theme.palette.primary.main,
                                width: isMobile ? '60px' : '80px',
                                height: isMobile ? '60px' : '80px',
                                borderRadius: '50%',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                        >
                            {social.icon}
                        </IconButton>
                    ))}
                </Box>

                {/* Copyright */}
                <Typography variant="h4" sx={{ color: theme.palette.background.paper, fontWeight: 400, }}>
                    © Copyright {new Date().getFullYear()} Hoveni - All Rights Reserved
                </Typography>
            </Container >
        </Box >
    );
};

export default Footer;
