import React from 'react';
import { Box, BoxProps } from '@mui/material';

const ContentMargin: React.FC<BoxProps> = ({ children, ...props }) => {

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: {
                    xs: '100%', // Mobile
                    sm: '900px', // Tablet
                    md: '1400px', // Desktop
                },
                margin: '0 auto',
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default ContentMargin;
