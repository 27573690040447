import { useEffect, useState, useRef } from "react";

import { useTheme } from "@mui/material/styles";
import {
    Box, useMediaQuery, Typography, IconButton,
    Table, TableBody, TableCell, TableRow, CardMedia

} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import ContentMargin from '../../components/ContentMargin/ContentMargin';
import Navbar from '../../components/Navbar/Navbar';
import ImageCollage from '../../components/ImageCollage/ImageCollage';

import ReactStars from 'react-stars'
import ActivityCard from "../../components/ActivityCard/ActivityCard";
import OSMMap from "../../components/OSMMap/OSMMap";
import GoToTopButton from "../../components/GoToTopButton/GoToTopButton";
import LoginDialog from "../../components/LoginDialog/LoginDialog";
import BookingDialog from "../../components/BookingDialog/BookingDialog";
import SuccessDialog from "../../components/SuccessDialog/SuccessDialog";
import BookingBar from "../../components/BookingBar/BookingBar";
import OverlaySpinner from "../../components/OverlaySpinner/OverlaySpinner";

import { getHomestay, sortImagePaths } from "../../services/homestayService";
import { Carousel } from 'react-responsive-carousel';
import { Homestay } from "../../types";
import { toast } from "react-toastify";
import { verifyAccessToken } from "../../services/authServices";
import copy from 'copy-to-clipboard';

// Assets
import locationIcon from "../../assets/location-icon.svg";
import ShareIcon from '@mui/icons-material/Share';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HomestayPage = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const [homestayDetails, setHomestayDetails] = useState<Homestay>();
    const galleryRef = useRef<HTMLDivElement | null>(null);

    const { id } = useParams<{ id: string }>();

    const [isLoading, setIsLoading] = useState(true);
    const [openLogin, setOpenLogin] = useState(false);
    const [openBooking, setOpenBooking] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isBookingSuccess, setIsBookingSuccess] = useState(false);
    const activitiesIDs: string[] = [];

    useEffect(() => {
        fetchHomestay();

        isUserLoggedIn();

        // Extract 'loggedIn' from query parameters
        const searchParams = new URLSearchParams(location.search);
        const loggedIn = searchParams.get('loggedIn') === 'true'; // Converts to a boolean

        // Set 'openBooking' based on the value of 'loggedIn'
        setOpenBooking(loggedIn);

        // eslint-disable-next-line
    }, [location.pathname, id]);

    // Login Dialog
    const handleLoginClose = () => {
        setOpenLogin(false);
    };

    // Booking Dialog
    const handleBookingClose = () => {
        setOpenBooking(false);
        window.history.replaceState({}, document.title, window.location.pathname + window.location.search.replace("?loggedIn=true", "").replace("&loggedIn=true", ""));
    }

    // Success Dialog
    const handleSuccessClose = () => {
        setOpenSuccess(false);
    }



    // Helper functions
    const fetchHomestay = async () => {
        const homestayID = id;
        if (!homestayID) {
            toast.error("Homestay ID is required");
            setIsLoading(false);
            return; // Exit if homestayID is undefined
        }

        try {
            const response = await getHomestay(homestayID);
            setHomestayDetails(response);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);

            setTimeout(() => {
                toast.info("Redirecting to Page Not Found");
            }, 2800)

            // Redirect to the "Page Not Found" route after 300 seconds (3000 milliseconds)
            setTimeout(() => {
                window.location.href = "/page-not-found";
            }, 5500);
        }
    };

    const formatImageArray = (num?: number) => {
        if (!homestayDetails?.images) {
            return [];
        }
        const images = sortImagePaths(homestayDetails.images).map((image) => {
            return { src: image, isLocal: false };
        }) || [];  // Fallback to empty array if homestayDetails?.images is undefined

        // If num is provided, return the first 'num' elements, otherwise return the full array
        return num ? images.slice(0, num) : images;
    };

    const shareHomestay = () => {
        const homestayUrl = window.location.href;

        if (navigator.share) {
            // Use Web Share API if available
            navigator
                .share({
                    title: homestayDetails?.name || "Hoveni Homestay",
                    text: `Check out this homestay on Hoveni: ${homestayDetails?.name || ""}`,
                    url: homestayUrl,
                })
                .then(() => {
                    toast.success("Shared successfully");
                })
                .catch((error) => {
                    toast.error("Failed to share");
                });
        } else if (navigator.clipboard) {
            // Fallback: Copy URL to clipboard if Web Share API is not supported
            navigator.clipboard.writeText(homestayUrl)
                .then(() => {
                    toast.success("Link copied to clipboard");
                })
                .catch(() => {
                    toast.error("Failed to copy the link");
                });
        } else {
            copy(homestayUrl);
            toast.success("Link copied to clipboard");
        }
    };



    const isUserLoggedIn = async () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setIsLoggedIn(false);
            return false;
        }

        try {
            await verifyAccessToken(token);
            setIsLoggedIn(true);
            return true;
        } catch (error) {
            setIsLoggedIn(false);
            return false;
        }
    };


    // Function to handle data sent from the child
    const handleBookingdata = async (data: any) => {
        // Check if the user is logged in
        const loggedIn = await isUserLoggedIn();
        if (loggedIn) {
            setOpenBooking(true);
        } else {
            setOpenLogin(true);
        }
    };

    const handleBookingSuccess = (data: boolean) => {
        window.history.replaceState({}, document.title, window.location.pathname + window.location.search.replace("?loggedIn=true", "").replace("&loggedIn=true", ""));
        setOpenSuccess(true);
        setIsBookingSuccess(data)
    }

    return (
        <Box
            component="main"
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "100vh",
                width: "100%",
            }}
        >
            <OverlaySpinner open={isLoading} />
            <Navbar />
            <ContentMargin>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        alignItems: "center",
                        minHeight: "100vh",
                        paddingX: { xs: 2, md: 2, lg: 0 },
                        marginBottom: isMobile ? 8 : 12,
                    }}
                >

                    {/* Section A - Header */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            width: "100%",
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                textAlign: "left",
                                marginTop: isMobile ? 4 : 8,
                            }}
                        >
                            {homestayDetails?.name}
                        </Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: isMobile ? "space-between" : "auto",
                                    width: isMobile ? "100%" : "auto",
                                    gap: 2,
                                    minWidth: '360px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                    }}>
                                    <img src={locationIcon} alt="location icon" />
                                    <Typography variant="h6" sx={{ fontSize: '18px' }} color="#8F908F">{homestayDetails?.location_short}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: '18px' }} color="rgba(0, 0, 0, 0.2)">|</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: '4px',
                                    }}>
                                    <ReactStars
                                        value={homestayDetails?.rating}
                                        edit={false}
                                        size={isMobile ? 18 : 34}
                                        half={true}
                                        color2="#ffd700"
                                    />
                                    <Typography variant="h6">{homestayDetails?.rating}</Typography>
                                </Box>
                            </Box>

                            <IconButton edge="end"
                                aria-label="share"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                    color: theme.palette.text.primary,
                                    margin: 0,
                                    marginTop: isMobile ? 2 : 0,
                                    paddingLeft: isMobile ? 0 : 1,
                                    borderRadius: theme.shape.borderRadius,
                                    transition: "all 0.3s ease",

                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    }
                                }}
                                onClick={() => shareHomestay()} >
                                <ShareIcon />
                                {
                                    isMobile ? <Typography variant="h6">Share Homestay</Typography> : null
                                }
                            </IconButton>
                        </Box>

                    </Box>

                    {/* Login Dialog Box */}
                    <LoginDialog open={openLogin} onClose={handleLoginClose} />


                    {/* Booking Dialog Box */}
                    {
                        isLoggedIn &&
                        (
                            <BookingDialog
                                open={openBooking}
                                onClose={handleBookingClose}
                                homestayID={homestayDetails?.id || ''}
                                activitiesIDs={activitiesIDs}
                                isSuccess={handleBookingSuccess}
                            />
                        )
                    }

                    {/* Booking Success Dialog Box */}
                    {
                        isBookingSuccess &&
                        (
                            <SuccessDialog open={openSuccess} onClose={handleSuccessClose} />
                        )
                    }

                    {/* Section B - Image Collage */}
                    {
                        isMobile && homestayDetails ? (
                            <div style={{ borderRadius: '16px', overflow: 'hidden' }}>
                                <Carousel
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    autoPlay={true}
                                    interval={5000}
                                    swipeScrollTolerance={10}
                                    preventMovementUntilSwipeScrollTolerance={true}
                                    onClickItem={() => galleryRef.current?.scrollIntoView({ behavior: 'smooth' })}
                                >
                                    {sortImagePaths(homestayDetails.images).map((image, index) => (
                                        <div key={index}>
                                            <CardMedia
                                                component="img"
                                                height="500"
                                                image={image}
                                                alt={`Homestay Image ${index + 1}`}
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    flexWrap: 'nowrap',
                                    width: '100%',
                                    height: '70vh',
                                    minHeight: '500px',
                                    overflow: 'scroll',
                                    borderRadius: 8,
                                    gap: 4,
                                }}
                            >
                                <ImageCollage images={formatImageArray()} />
                            </Box>
                        )
                    }


                    {/* Section C - Booking Bar */}
                    <BookingBar onSendData={handleBookingdata} />

                    {/* Section D - Pricing */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            gap: 2,
                            width: "100%",
                            marginTop: 1,
                            trasition: "all 0.3s ease",
                            paddingY: 2,
                            borderRadius: theme.shape.borderRadius,

                            '&:hover': {
                                cursor: "pointer",
                                backgroundColor: theme.palette.primary.dark,
                            }
                        }}
                    >
                        Price:
                        {homestayDetails && homestayDetails.prices[0].discounted_price > 0 ? (
                            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'baseline', gap: isMobile ? '4px' : '16px' }}>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        color: theme.palette.text.primary,
                                    }}
                                >
                                    ₹{homestayDetails?.prices[0].discounted_price}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    fontWeight="500"
                                    sx={{
                                        textDecoration: 'line-through',
                                        color: theme.palette.text.disabled,
                                        display: '-webkit-box',
                                    }}
                                >
                                    ₹{homestayDetails?.prices[0].base_price}
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                                ₹{homestayDetails?.prices[0].base_price}
                            </Typography>
                        )}
                        (per person - per night)
                    </Box>

                    {/* Section E - About the Homestay + Amenities */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            overflow: 'hidden',
                            gap: isMobile ? 2 : 4,
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                textAlign: "left",
                                marginTop: isMobile ? 2 : 4,
                            }}
                        >
                            About the Homestay
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: "left",
                            }}
                        >
                            {homestayDetails?.description}
                        </Typography>


                        <Box>
                            <Typography
                                variant="h1"
                                sx={{
                                    textAlign: "left",
                                    marginY: isMobile ? 2 : 4,
                                }}
                            >
                                Amenities
                            </Typography>

                            <Box sx={{ overflowX: 'auto' }}>
                                <Table
                                    sx={{
                                        width: '100%',
                                        borderCollapse: 'collapse',  // Remove default border spacing
                                    }}
                                >
                                    <TableBody>
                                        {homestayDetails?.amenities.map((amenity, index) => (
                                            <TableRow key={index} sx={{
                                                transition: 'all 0.3s ease',
                                                cursor: 'pointer',

                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                }
                                            }}>
                                                {/* Icon and Name */}
                                                <TableCell
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderBottom: 'none',  // No bottom border
                                                        padding: '8px 12px 0px 0px',
                                                    }}
                                                >
                                                    {/*  Icon is disabled for the time-being */}
                                                    {/* <img
                                                        src={amenity.icon}
                                                        alt={amenity.name}
                                                        style={{ width: 24, height: 24, marginRight: 8 }}
                                                    /> */}
                                                    <strong style={{ fontSize: '1.2rem' }}>{amenity.name}</strong>
                                                </TableCell>

                                                {/* Description */}
                                                <TableCell
                                                    sx={{
                                                        borderBottom: 'none',  // No bottom border
                                                        padding: '8px 0px',
                                                        fontSize: '1.2rem'
                                                    }}
                                                >
                                                    {amenity.description}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>

                    </Box>

                    {/* Section F - Activities */}
                    {
                        homestayDetails?.activities && homestayDetails?.activities.length > 0 ? (

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: 'auto',
                                    overflow: 'hidden',
                                    gap: isMobile ? 2 : 4,
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        textAlign: "left",
                                        marginTop: isMobile ? 4 : 8,
                                    }}
                                >
                                    Activities
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    alignItems: isMobile ? 'center' : 'auto',
                                    width: '100%',
                                    height: 'auto',
                                    overflow: 'hidden',
                                    gap: isMobile ? 2 : 4,
                                }}>
                                    {
                                        homestayDetails?.activities.map((activity, index) => (
                                            <ActivityCard
                                                key={index}
                                                activity={activity}
                                            />
                                        ))
                                    }
                                </Box>

                            </Box>

                        ) : null
                    }

                    {/* Section G - Gallery */}
                    <Box
                        ref={galleryRef}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            overflow: 'hidden',
                            gap: isMobile ? 2 : 4,
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                textAlign: "left",
                                marginTop: isMobile ? 4 : 8,
                            }}
                        >
                            Gallery
                        </Typography>
                        <Box
                            paddingX={0}
                        >
                            <ImageCollage images={formatImageArray()} />
                        </Box>


                    </Box>

                    {/* Section H - Location */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            overflow: 'hidden',
                            gap: isMobile ? 2 : 4,
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                textAlign: "left",
                                marginTop: isMobile ? 4 : 8,
                            }}
                        >
                            Location
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                height: 'auto',
                                overflow: 'hidden',
                                gap: 4,
                            }}
                        >
                            {/* Left Side - Text */}
                            <Box
                                sx={{
                                    flex: 2,
                                    padding: '24px',
                                    display: 'flex',
                                    gap: 4,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: '16px',
                                }}
                            >

                                <Typography
                                    variant="h2">
                                    {homestayDetails?.location.short_name}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    sx={{
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 16,
                                    }}
                                >
                                    {homestayDetails?.location.description}
                                </Typography>

                            </Box>

                            {/* Right Side - Map */}
                            <Box
                                sx={{
                                    flex: 3,
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '16px',
                                    minHeight: '400px',
                                }}
                            >
                                <OSMMap latitude={homestayDetails?.location.latitude} longitude={homestayDetails?.location.longitude} />
                            </Box>
                        </Box>
                    </Box>

                    {/* Section I - Policies */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            overflow: 'hidden',
                            gap: isMobile ? 2 : 4,
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                textAlign: "left",
                                marginTop: isMobile ? 4 : 8,
                            }}
                        >
                            Policies
                        </Typography>

                        <Box sx={{ overflowX: 'auto' }}>
                            <Table
                                sx={{
                                    width: '100%',
                                    borderCollapse: 'collapse',  // Remove default border spacing
                                }}
                            >
                                <TableBody>
                                    {homestayDetails?.policies.map((policy, index) => (
                                        <TableRow key={index} sx={{
                                            transition: 'all 0.3s ease',
                                            cursor: 'pointer',

                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            }
                                        }}>
                                            {/* Icon and Title */}
                                            <TableCell
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom: 'none',  // No bottom border
                                                    padding: '8px 12px 0px 0px',
                                                }}
                                            >
                                                {/*  Icon is disabled for the time-being */}
                                                {/* <img
                                                        src={policy.icon}
                                                        alt={policy.title}
                                                        style={{ width: 24, height: 24, marginRight: 8 }}
                                                    /> */}
                                                <strong style={{ fontSize: '1.2rem' }}>{policy.title}</strong>
                                            </TableCell>

                                            {/* Description */}
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',  // No bottom border
                                                    padding: '8px 0px',
                                                    fontSize: '1.2rem'
                                                }}
                                            >
                                                {policy.description}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>

                </Box>

                <GoToTopButton />

            </ContentMargin >
            <Footer />
        </Box >
    );
};

export default HomestayPage;