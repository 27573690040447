import React, { useState, useEffect } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../../theme';

const GoToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Show button when page is scrolled down
    const toggleVisibility = () => {
        if (window.scrollY > 400) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the window to the top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <IconButton
                    onClick={scrollToTop}
                    size='large'
                    sx={{
                        position: 'fixed',
                        bottom: isMobile ? '16px' : '56px',
                        right: isMobile ? '16px' : '56px',
                        borderRadius: '50px',
                        color: theme.palette.text.primary,
                        padding: '16px',
                        zIndex: '1000',
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: `2px 4px 16px 0px rgba(0, 0, 0, 0.2)`,
                        transition: 'all 0.3s ease',

                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },

                        '&:active': {
                            transform: 'scale(0.95)',
                        }
                    }}
                >
                    <KeyboardArrowUpIcon />
                </IconButton>
            )}
        </div>
    );
};

export default GoToTopButton;
