import React from 'react';
import {
    Dialog,
    Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import ButtonComponent from '../ButtonComponent/ButtonComponent';

// Assets
import CloseIcon from '@mui/icons-material/Close';
import hoveniLogo from "../../assets/hoveni-logo.svg";

interface SuccessDialogProps {
    open: boolean;
    onClose: () => void;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black backdrop
                    boxShadow: 'none',
                    maxWidth: isMobile ? '100%' : '600px',
                    minWidth: '360px',
                },
            }}
        >
            <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{
                        width: isMobile ? '90%' : '400px',
                        padding: 4,
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4
                    }}>
                    {/* Close button */}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8, }} // Close button color
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Logo */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'auto',
                            mb: 2
                        }}
                    >
                        <img
                            src={hoveniLogo}
                            alt="Hoveni Logo"
                            style={{
                                maxHeight: isMobile ? '48px' : '60px',
                                width: 'auto',
                            }}
                        />
                    </Box>

                    <Typography variant="h3" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Thank You for Booking!
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        Our <b>customer representative</b> will get in <u>touch with you </u> shortly. <br /> <br />
                        This typically takes under 24 hours. <br />
                    </Typography>

                    <Typography variant="caption" sx={{ textAlign: 'left', mb: 2 }}>
                        Kindly keep an eye on your <b>email</b> & <b> mobile-number</b> for further updates.

                        <br /> <br />

                        Feel free to <a href='/#contact-us' target='_blank'>reach out to us</a> at: <a href="https://wa.me/917908301284">+91 7908-301-284</a>
                        &nbsp;or write to us @&nbsp;<a href="mailto:contact@hoveni.com">contact@hoveni.com</a>
                    </Typography>

                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0, m: 0 }}>

                        <ButtonComponent
                            label="Close"
                            variant="normal"
                            onClick={onClose}
                            sx={{ width: '100%' }}
                        />

                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
};

export default SuccessDialog;
