import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import activitiesReducer from "./activitiesSlice";

// Configuration for persisting the activities reducer
const persistConfig = {
  key: "root", // This key will be used to store your persisted state
  storage,
  whitelist: ["activities"],
};

const persistedActivitiesReducer = persistReducer(
  persistConfig,
  activitiesReducer
);

export const store = configureStore({
  reducer: {
    activities: persistedActivitiesReducer,
  },
});

export const persistor = persistStore(store); // This will be used in your app entry point

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
